import React from "react"
import Periodicity from "../static/periodicityinK-theory.pdf" // Tell webpack this JS file uses this pdf

const IndexPage = () => (
  <>
    <a href={Periodicity}>pdf</a>
  </>
)

export default IndexPage

